import {
  InstructorImg,
  InstructorImgPlaceholder,
  LangsMenusWrapper,
  WhiteArrowWrapper,
} from "../MainSideBar/MainSideBar.styled";
import SideBarProfileSkeleton from "@Components/Skeletons/SideBarProfileSkeleton";
import { _GetTeacherProfile } from "@Services/Teachers";
import { TeachersDatasContext } from "context/Teachers.context";
import { ProfileIcon } from "images/icons/ProfileIcon";
import { SubjectIcon } from "images/icons/SubjectIcon";
import { WhiteArrowUp } from "images/icons/WhiteArrowUp";
import { useContext, Fragment, useState, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import {
  ButtonWrapper,
  InstructorData,
  InstructorDataWrapper,
  MenuSidebar,
  MenuSidebarButons,
  MenuSidebarContent,
  ProfileEmail,
  ProfileName,
  ProfileTitleWrapper,
  SideBtnText,
} from "./MainSideBar.styled";
import { BaimsLogo } from "images/logos/BaimsLogo";
import { TextBaimsLogo } from "images/logos/TextBaimsLogo";
import { useTranslation } from "react-i18next";
import { useTablesFilters } from "context/TablesFilters.context";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";

type Props = {
  setOpenLogoutMenu: Dispatch<SetStateAction<boolean>>;
};

const MainSideBar = ({ setOpenLogoutMenu }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [load, setLoad] = useState(false);
  const { userData, teacherSidebar, subjectSidebar, setSubjectSidebar } = userInfo();
  const { teacherId, teacherProfile, profileFetching } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const path = location?.pathname.split("/")[location?.pathname.split("/").length - 1];
  const [tabSelected, setTabSelected] = useState(path === "profile" ? 2 : 1);

  return (
    <MenuSidebar isOpen={teacherSidebar} isSubjectSidebarOpen={subjectSidebar}>
      <MenuSidebarContent isSubjectSidebarOpen={subjectSidebar}>
        <ProfileTitleWrapper isSubjectSidebarOpen={subjectSidebar}>
          {userData?.roles.includes(ADMIN) ? (
            <Fragment>
              {!profileFetching ? (
                <Fragment>
                  {!load ? (
                    <InstructorImg
                      src={teacherProfile?.image}
                      alt={teacherProfile?.display_name}
                      onError={() => setLoad(true)}
                    />
                  ) : (
                    <InstructorImgPlaceholder />
                  )}
                  {!subjectSidebar && (
                    <InstructorData>
                      <ProfileName>{teacherProfile?.display_name}</ProfileName>
                      <ProfileEmail>{teacherProfile?.name}</ProfileEmail>
                    </InstructorData>
                  )}
                </Fragment>
              ) : (
                <SideBarProfileSkeleton />
              )}
            </Fragment>
          ) : (
            <>{subjectSidebar ? <BaimsLogo /> : <TextBaimsLogo />}</>
          )}
        </ProfileTitleWrapper>
        <MenuSidebarButons isAdmin={userData?.roles.includes(ADMIN)}>
          <Link
            className={tabSelected === 1 ? "side_button" : "not_active"}
            to={!isSectionsSorted && `/${teacherId}/subjects`}
          >
            <ButtonWrapper
              openSubjectSidebar={subjectSidebar}
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  setTabSelected(1);
                }
              }}
              className={tabSelected === 1 ? "is_active" : ""}
            >
              <SubjectIcon />
              {!subjectSidebar && (
                <SideBtnText className={tabSelected === 1 ? "side_button" : "not_active"}>
                  {tCommon("subjects")}
                </SideBtnText>
              )}
            </ButtonWrapper>
          </Link>
          <Link
            className={tabSelected === 2 ? "side_button" : "not_active"}
            to={!isSectionsSorted && `/${teacherId}/profile`}
          >
            <ButtonWrapper
              openSubjectSidebar={subjectSidebar}
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  setTabSelected(2);
                  setSubjectSidebar(false);
                }
              }}
              className={tabSelected === 2 ? "is_active" : ""}
            >
              <ProfileIcon />
              {!subjectSidebar && (
                <SideBtnText className={tabSelected === 2 ? "side_button" : "not_active"}>
                  {tCommon("profile")}
                </SideBtnText>
              )}
            </ButtonWrapper>
          </Link>
        </MenuSidebarButons>
        {!userData?.roles.includes(ADMIN) && (
          <LangsMenusWrapper
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                setOpenLogoutMenu(true);
              }
            }}
          >
            {!profileFetching ? (
              <InstructorDataWrapper>
                <InstructorImg
                  src={teacherProfile?.image}
                  alt={teacherProfile?.display_name}
                  onError={() => setLoad(true)}
                />
                {!subjectSidebar && (
                  <Fragment>
                    <InstructorData>
                      <ProfileName>{teacherProfile?.display_name}</ProfileName>
                      <ProfileEmail>{teacherProfile?.name}</ProfileEmail>
                    </InstructorData>
                  </Fragment>
                )}
              </InstructorDataWrapper>
            ) : (
              <SideBarProfileSkeleton />
            )}
            {!subjectSidebar && (
              <WhiteArrowWrapper>
                <WhiteArrowUp />
              </WhiteArrowWrapper>
            )}
          </LangsMenusWrapper>
        )}
      </MenuSidebarContent>
    </MenuSidebar>
  );
};

export default MainSideBar;
