import { useContext, Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import { _CreateSubject } from "@Services/Subjects";
import PopupsLayout from "@Components/PopupsLayout";
import { useForm, Controller } from "react-hook-form";
import { Subject } from "types/Subjects";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { _GetCurrencies, _GetUploadUrl, _PutImageFile } from "@Services/Common";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FormHelperText } from "@mui/material";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { ChromePicker } from "react-color";
import {
  CenterDiv,
  ColorPickerContainer,
  DashboardContainer,
  DeleteFile,
  DisplayColor,
  FileInputWrapper,
  FileNameWrapper,
  PickColorWrapper,
  PopupSectionTitle,
  StyledPTag,
  SubjectImage,
  UploadFileInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import { FileUploader } from "react-drag-drop-files";
import { AddCourseMessage, ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import { imagesTypes } from "constants/Types";
import UploadIcon from "images/icons/UploadIcon";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import { compareEmojis, convertSize, removeDuplicates } from "utils/Strings";
import { fileToBinary } from "utils/Images";
import {
  BreadcrumbText,
  CategoriesWrappers,
  CategoryError,
  DeleteCategoryIcon,
  DisplayBreadcrumb,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { CloseIcon } from "images/icons/CloseIcon";
import BreadcrumbPortalButton from "@Components/Breadcrumbs/BreadcrumbPortalMenu/BreadcrumbPortalButton";
import BreadcrumbsMenuPortal from "@Components/Breadcrumbs/BreadcrumbPortalMenu";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  subjectsRefetch: () => void;
}

const AddSubjectPopup = ({ isOpen, closePopup, subjectsRefetch }: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const { userData } = userInfo();
  const { teacherId } = useContext(TeachersDatasContext);
  const buttonRef = useRef(null);
  const colorPickerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(null);
  const [submitValidation, setSubmitValidation] = useState("");
  const [categoriesValidation, setCategoriesValidation] = useState("");
  const [color, setColor] = useState("#45AAF2");
  const [openColorPalltie, setOpenColorPalltie] = useState(false);
  const [uploadImageError, setUploadImageError] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [docImageName, setDocImageName] = useState([]);
  const [imageName, setImageName] = useState("");
  const [imageExe, setImageExe] = useState("");
  const [imageBinaryFile, setImageBinaryFile] = useState(null);
  const [isdropActive, setIsdropActive] = useState(false);
  const [placement, setPlacement] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const catIds = placement?.map((item) => Number(item.lastCategoryId));
  const { data: currenciesList } = useQuery(["currencies", language], () => _GetCurrencies());
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Subject>();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setImageFile(null);
      setSubmitValidation("");
      setUploadImageError("");
      setOpenColorPalltie(false);
      setValue("color", "#45AAF2");
      setColor("#45AAF2");
      setPlacement([]);
      setCategoriesValidation("");
      setIsDropdownOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.className.includes("saturation-white") &&
        !event.target.className.includes("hue-horizontal") &&
        !event.target.className.includes("DisplayColor") &&
        !event.srcElement.id.includes("rc-editable-input")
      ) {
        setOpenColorPalltie(false);
      }
      if (!event.target.className.includes("Breadcrumbsstyled")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    setValue("color", newColor.hex);
  };

  const handleInputChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    setValue("color", newColor);
  };

  const removeCat = (id) => {
    if (id) {
      setPlacement(placement?.filter((plac) => plac.id !== id));
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (placement?.length > 0) {
      setCategoriesValidation("");
      const firstEmojie = placement[0].hierarcyText.split("/")[0];
      if (compareEmojis(firstEmojie, "🇰🇼")) {
        setValue("currency", "kwd");
      } else if (compareEmojis(firstEmojie, "🇸🇦")) {
        setValue("currency", "sar");
      } else if (compareEmojis(firstEmojie, "🇯🇴")) {
        setValue("currency", "jod");
      } else if (compareEmojis(firstEmojie, "🇧🇭")) {
        setValue("currency", "bhd");
      } else if (compareEmojis(firstEmojie, "🇹🇷")) {
        setValue("currency", "try");
      }
    }
  }, [placement]);

  useEffect(() => {
    if (imageFile) {
      const lastDotIndex = imageFile?.name.lastIndexOf(".");
      setDocImageName(imageFile?.name.substring(0, lastDotIndex));
      fileToBinary(imageFile).then((binaryImage) => {
        setImageBinaryFile(binaryImage);
      });
      if (docImageName) {
        setImageName(imageFile?.name.substring(0, lastDotIndex));
        setImageExe(imageFile?.name.substring(lastDotIndex + 1));
      }
    }
  }, [imageFile, docImageName]);

  const submitHandler = (data: Subject) => {
    if (placement?.length !== 0) {
      setIsLoading(true);
      if (imageFile) {
        _CreateSubject({
          ...data,
          image: {
            filename: imageName,
            extension: imageExe,
            size: imageFile.size,
          },
          category_ids: removeDuplicates(catIds),
          is_active: data?.is_active === "false" ? false : true,
          is_light_theme: data?.is_light_theme === "false" ? false : true,
          instructor_id: teacherId,
        })
          .then((res) => {
            _GetUploadUrl({
              key: imageName,
              content_type: imageExe,
              folder_path: String(res.data.data.media_id),
              use_original_filename: true,
            })
              .then((res) => {
                _PutImageFile(res.signed_url, imageBinaryFile).catch((err) => {
                  setSubmitValidation(err?.response.data.message);
                });
              })
              .then(() => {
                subjectsRefetch();
                closePopup(false);
              })
              .catch((err) => {
                setSubmitValidation(err?.response.data.message);
              });
          })
          .catch((err) => {
            setSubmitValidation(err?.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        _CreateSubject({
          ...data,
          category_ids: removeDuplicates(catIds),
          is_active: data?.is_active === "false" ? false : true,
          is_light_theme: data?.is_light_theme === "false" ? false : true,
          instructor_id: teacherId,
        })
          .then(() => {
            subjectsRefetch();
            closePopup(false);
          })
          .catch((err) => {
            setSubmitValidation(err?.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_subject")}
          buttonTitle={tCommon("add_subject")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
          placement={placement}
          setCategoriesValidation={setCategoriesValidation}
          isSubjectPopups
        >
          <FormWrapper>
            {isRegularInstructor && <AddCourseMessage>{tCommon("add_subject_message")}</AddCourseMessage>}
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs
              isDisabled={isRegularInstructor}
              isSubject
              isOpen={isOpen}
              errors={errors}
              watch={watch}
              register={register}
            />
            <PopupSectionTitle>{tCommon("image")}</PopupSectionTitle>
            <DashboardContainer>
              <div className="container">
                {imageFile === null ? (
                  <>
                    {isRegularInstructor ? (
                      <UploadFileInput isImage style={{ cursor: "not-allowed" }}>
                        <CenterDiv>
                          <UploadIcon />
                          {isdropActive ? (
                            <StyledPTag>{tCommon("drop_it_here")}</StyledPTag>
                          ) : (
                            <StyledPTag>
                              <span>{tCommon("select")}</span> {tCommon("cover_image")}
                            </StyledPTag>
                          )}
                        </CenterDiv>
                      </UploadFileInput>
                    ) : (
                      <UploadFileInput isImage isdropActive={isdropActive}>
                        <CenterDiv>
                          <UploadIcon />
                          {isdropActive ? (
                            <StyledPTag>{tCommon("drop_it_here")}</StyledPTag>
                          ) : (
                            <StyledPTag>
                              <span>{tCommon("select")}</span> {tCommon("cover_image")}
                            </StyledPTag>
                          )}
                        </CenterDiv>
                        <FileUploader
                          name="image"
                          types={imagesTypes}
                          maxSize={2}
                          onDraggingStateChange={(e) => setIsdropActive(e)}
                          handleChange={(e) => setImageFile(e)}
                          onSizeError={() => setUploadImageError(tCommon("maximum_allowed"))}
                          onTypeError={() => setUploadImageError(tCommon("wrong_type"))}
                        />
                      </UploadFileInput>
                    )}
                  </>
                ) : (
                  <UploadFileInput isImage>
                    <FileInputWrapper>
                      <FileNameWrapper>
                        <SubjectImage src={URL.createObjectURL(imageFile)} alt="subject_image" />
                        <StyledPTag noMargin>
                          {imageFile.name} ({convertSize(imageFile.size)})
                        </StyledPTag>
                      </FileNameWrapper>
                      <DeleteFile type="button" onClick={() => setImageFile(null)}>
                        <BoldCloseIcon />
                      </DeleteFile>
                    </FileInputWrapper>
                  </UploadFileInput>
                )}
                {uploadImageError && imageFile === null && <ErrorType center>{uploadImageError}</ErrorType>}
              </div>
            </DashboardContainer>
            <PopupSectionTitle>{tCommon("subject_theme_color")}</PopupSectionTitle>
            <PickColorWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                disabled={isRegularInstructor}
                label={tCommon("subject_color")}
                {...register("color", {
                  required: {
                    value: true,
                    message: tCommon("required_message"),
                  },
                  pattern: {
                    value: /^#([0-9A-F]{3}){1,2}$/i,
                    message: tCommon("color_validation_message"),
                  },
                })}
                value={watch("color")}
                onChange={handleInputChange}
                error={Boolean(errors.color)}
                helperText={errors.color && errors?.color.message}
                InputLabelProps={{
                  shrink: watch("color") && true,
                }}
                InputProps={{
                  endAdornment: (
                    <DisplayColor
                      pickedColor={color}
                      isDisabled={isRegularInstructor}
                      onClick={() => {
                        if (!isRegularInstructor) {
                          setOpenColorPalltie(!openColorPalltie);
                        }
                      }}
                    />
                  ),
                }}
                ref={colorPickerRef}
              />
              <ColorPickerContainer className="custom-chrome-picker" openColorPalltie={openColorPalltie}>
                <ChromePicker color={color} onChange={handleChange} />
              </ColorPickerContainer>
            </PickColorWrapper>
            <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("light_dark_mode")}</InputLabel>
              <Controller
                name="is_light_theme"
                control={control}
                defaultValue={"true"}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("light_mode")}</MenuItem>
                    <MenuItem value="false">{tCommon("dark_mode")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <PopupSectionTitle>{tCommon("price")}</PopupSectionTitle>
            <InputsWrapper>
              <Input id="filled-basic" variant="filled" label={tCommon("total_price")} disabled value={"0"} />
              <FormControl variant="filled" fullWidth error={Boolean(errors.currency)} disabled={isRegularInstructor}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("currency")}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: tCommon("required_message"),
                  }}
                  render={({ field }) => (
                    <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                      {currenciesList?.map((cur: string, index: number) => (
                        <MenuItem key={index} value={cur} onClick={() => setPlacement([])}>
                          {cur.toLocaleUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.currency && <FormHelperText>{errors.currency?.message}</FormHelperText>}
              </FormControl>
            </InputsWrapper>
            <InputsWrapper>
              <Input id="filled-basic" variant="filled" label={tCommon("final_price")} disabled defaultValue={"0"} />
              <Input id="filled-basic" variant="filled" label={tCommon("discount")} defaultValue={"0%"} disabled />
            </InputsWrapper>
            <PopupSectionTitle>{tCommon("study_stage_college")}</PopupSectionTitle>
            <CategoriesWrappers>
              {placement.map((placement, index) => (
                <DisplayBreadcrumb key={index}>
                  <BreadcrumbText isDisabled={isRegularInstructor}>{placement.hierarcyText}</BreadcrumbText>
                  <DeleteCategoryIcon isDisabled={isRegularInstructor} onClick={() => removeCat(placement.id)}>
                    <CloseIcon width="12" height="12" />
                  </DeleteCategoryIcon>
                </DisplayBreadcrumb>
              ))}
              <BreadcrumbPortalButton
                placement={placement}
                buttonRef={buttonRef}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                categoriesValidation={categoriesValidation}
              >
                {categoriesValidation && <CategoryError>{categoriesValidation}</CategoryError>}
                <BreadcrumbsMenuPortal
                  buttonRef={buttonRef}
                  isDropdownOpen={isDropdownOpen}
                  setIsDropdownOpen={setIsDropdownOpen}
                  setPlacement={setPlacement}
                  placement={placement}
                  currentCurrency={watch("currency")}
                />
              </BreadcrumbPortalButton>
            </CategoriesWrappers>
            <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
            <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="is_active"
                control={control}
                defaultValue={"true"}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("active")}</MenuItem>
                    <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddSubjectPopup;
