import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import { _UpdateSubject } from "@Services/Subjects";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Subject } from "types/Subjects";
import { useForm, Controller } from "react-hook-form";
import PopupsLayout from "@Components/PopupsLayout";
import { _CreateMediaFile, _DeleteUploadFile, _GetCurrencies, _GetUploadUrl, _PutImageFile } from "@Services/Common";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { calculateDiscount } from "utils/Forms";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { ChromePicker } from "react-color";
import {
  ActionIconsWrapper,
  CenterDiv,
  ColorPickerContainer,
  DashboardContainer,
  DeleteFile,
  DisplayColor,
  DownloadFileWrapper,
  FileInputWrapper,
  FileNameWrapper,
  PickColorWrapper,
  PopupSectionTitle,
  StyledPTag,
  SubjectImage,
  UploadFileInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import UploadIcon from "images/icons/UploadIcon";
import { FileUploader } from "react-drag-drop-files";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import { imagesTypes } from "constants/Types";
import { convertSize, downloadFile, isCategoriesHasChanged, removeDuplicates } from "utils/Strings";
import { ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import DownloadIcon from "images/icons/DownloadIcon";
import { fileToBinary } from "utils/Images";
import {
  BreadcrumbText,
  CategoriesWrappers,
  CategoryError,
  DeleteCategoryIcon,
  DisplayBreadcrumb,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { CloseIcon } from "images/icons/CloseIcon";
import BreadcrumbPortalButton from "@Components/Breadcrumbs/BreadcrumbPortalMenu/BreadcrumbPortalButton";
import BreadcrumbsMenuPortal from "@Components/Breadcrumbs/BreadcrumbPortalMenu";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSubjectPopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const { userData } = userInfo();
  const { subjectId, refetchSubjectStatistics, subjectStatistics, teacherId } = useContext(TeachersDatasContext);
  const colorPickerRef = useRef(null);
  const buttonRef = useRef(null);
  const [disablebutton, setDisablebutton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const [categoriesValidation, setCategoriesValidation] = useState("");
  const [color, setColor] = useState("#45AAF2");
  const [colorChanged, setColorChanged] = useState(false);
  const [openColorPalltie, setOpenColorPalltie] = useState(false);
  const [uploadImageError, setUploadImageError] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [docImageName, setDocImageName] = useState([]);
  const [imageName, setImageName] = useState("");
  const [imageExe, setImageExe] = useState("");
  const [imageBinaryFile, setImageBinaryFile] = useState(null);
  const [uplaodNewImage, setUplaodNewImage] = useState(false);
  const [imageHasError, steImageHasError] = useState(false);
  const [isdropActive, setIsdropActive] = useState(false);
  const { data: currenciesList } = useQuery(["currencies", language], () => _GetCurrencies());
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);
  const isNotAdmin = !userData?.roles.includes(ADMIN);
  const [placement, setPlacement] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const catIds = placement?.map((item) => Number(item.lastCategoryId));
  const catIds2 = subjectStatistics?.categoryHierarcy?.map((item) => Number(item.lastCategoryId));

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Subject>();

  useEffect(() => {
    if (subjectStatistics) {
      reset({
        lang_name: {
          en: subjectStatistics?.lang_name?.en,
          ar: subjectStatistics?.lang_name?.ar,
          tr: subjectStatistics?.lang_name?.tr,
        },
        description: {
          en: subjectStatistics?.description?.en,
          ar: subjectStatistics?.description?.ar,
          tr: subjectStatistics?.description?.tr,
        },
        label: {
          en: subjectStatistics?.label?.en,
          tr: subjectStatistics?.label?.tr,
          ar: subjectStatistics?.label?.ar,
        },
        final_price: subjectStatistics?.final_price,
        currency: subjectStatistics?.currency,
        is_active: subjectStatistics?.is_active.toString(),
        is_light_theme: subjectStatistics?.is_light_theme.toString(),
        color: subjectStatistics?.color,
      });
    }
    setPlacement(
      subjectStatistics?.categoryHierarcy?.map((entry) => ({
        hierarcyText: entry?.hierarcyText.replace(/\//g, " / "),
        lastCategoryId: entry?.lastCategoryId,
        id: Math.random(),
      }))
    );
    setIsDropdownOpen(false);
    setColor(subjectStatistics?.color);
    setOpenColorPalltie(false);
    setUplaodNewImage(false);
    steImageHasError(false);
    setSubmitValidation("");
    setCategoriesValidation("");
    setUploadImageError("");
    setImageFile(null);
    setColorChanged(false);
    setDisablebutton(true);
  }, [subjectStatistics, isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.className.includes("saturation-white") &&
        !event.target.className.includes("hue-horizontal") &&
        !event.target.className.includes("DisplayColor") &&
        !event.srcElement.id.includes("rc-editable-input")
      ) {
        setOpenColorPalltie(false);
      }
      if (!event.target.className.includes("Breadcrumbsstyled")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  function isValidHex(hex: string) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
  }

  useEffect(() => {
    if (placement?.length > 0) {
      setCategoriesValidation("");
    }
  }, [placement]);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    setValue("color", newColor.hex);
    setColorChanged(true);
  };

  const removeCat = (id) => {
    if (!isRegularInstructor) {
      setPlacement(placement?.filter((plac) => plac.id !== id));
      setIsDropdownOpen(false);
    }
  };

  const handleInputChange = (event) => {
    setColorChanged(true);
    const newColor = event.target.value;
    if (isValidHex(newColor)) {
      setColor(newColor);
      setValue("color", newColor);
    } else {
      setValue("color", newColor);
    }
  };

  useEffect(() => {
    if (imageFile) {
      const lastDotIndex = imageFile?.name.lastIndexOf(".");
      setDocImageName(imageFile?.name.substring(0, lastDotIndex));
      fileToBinary(imageFile).then((binaryImage) => {
        setImageBinaryFile(binaryImage);
      });
      if (docImageName) {
        setImageName(imageFile?.name.substring(0, lastDotIndex));
        setImageExe(imageFile?.name.substring(lastDotIndex + 1));
      }
    }
  }, [imageFile, docImageName]);

  const submitForm = async (data: Subject) => {
    const { lang_name, description } = data;
    if (placement?.length !== 0) {
      setIsLoading(true);

      try {
        if (imageFile) {
          let mediaId;
          if (subjectStatistics?.image && !imageHasError) {
            await _DeleteUploadFile({
              data: {
                folder_path: subjectStatistics.media.id,
                key: subjectStatistics.media.file_name,
              },
            });
          }

          if (!subjectStatistics?.media?.id) {
            mediaId = await _CreateMediaFile(subjectStatistics?.id);
          }

          const uploadUrlResponse = await _GetUploadUrl({
            folder_path: subjectStatistics?.media?.id ? subjectStatistics?.media.id : mediaId?.data.data.id.toString(),
            key: imageName,
            content_type: imageExe,
            use_original_filename: true,
          });

          await _PutImageFile(uploadUrlResponse.signed_url, imageBinaryFile);

          await _UpdateSubject(
            subjectId,
            isRegularInstructor
              ? { lang_name, description }
              : {
                  ...data,
                  image: {
                    filename: imageName,
                    extension: imageExe,
                    size: imageFile.size,
                  },
                  is_image_changed: true,
                  category_ids: removeDuplicates(catIds),
                  is_category_changed: true,
                  is_active: data?.is_active === "false" ? false : true,
                  is_light_theme: data?.is_light_theme === "false" ? false : true,
                },
            teacherId
          );

          closePopup(false);
          refetchSubjectStatistics();
        } else {
          await _UpdateSubject(
            subjectId,
            isRegularInstructor
              ? { lang_name, description }
              : {
                  ...data,
                  is_image_changed: false,
                  category_ids: removeDuplicates(catIds),
                  is_category_changed: !isCategoriesHasChanged(catIds, catIds2),
                  is_active: data?.is_active === "false" ? false : true,
                  is_light_theme: data?.is_light_theme === "false" ? false : true,
                },
            teacherId
          );
          closePopup(false);
          refetchSubjectStatistics();
        }
      } catch (err) {
        setSubmitValidation(err?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_subject")}
          buttonTitle={tCommon("save_changes")}
          buttonState={
            Boolean(!isDirty && disablebutton) && !imageFile && !colorChanged && isCategoriesHasChanged(catIds, catIds2)
          }
          closePopup={closePopup}
          noteMessage={tCommon("edit_item_message")}
          showDeleteButton
          deleteButtonStatus={isRegularInstructor}
          deleteTitle={tCommon("delete_subject")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
          placement={placement}
          setCategoriesValidation={setCategoriesValidation}
          isSubjectPopups
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs
              isSubject
              isOpen={isOpen}
              data={subjectStatistics}
              setValue={setValue}
              errors={errors}
              watch={watch}
              setDisablebutton={setDisablebutton}
              register={register}
            />
            <PopupSectionTitle>{tCommon("image")}</PopupSectionTitle>
            <DashboardContainer>
              <div className="container">
                {subjectStatistics?.image && !uplaodNewImage && !imageHasError ? (
                  <UploadFileInput isImage>
                    <FileInputWrapper>
                      <FileNameWrapper>
                        <SubjectImage
                          src={subjectStatistics?.image}
                          alt="subject_image"
                          onError={() => steImageHasError(true)}
                        />
                        <StyledPTag noMargin>
                          {subjectStatistics?.media?.file_name} ({convertSize(subjectStatistics?.media?.size)})
                        </StyledPTag>
                      </FileNameWrapper>
                      <ActionIconsWrapper>
                        <DownloadFileWrapper
                          onClick={() => downloadFile(subjectStatistics?.image, subjectStatistics?.media?.file_name)}
                        >
                          <DownloadIcon />
                        </DownloadFileWrapper>
                        <DeleteFile type="button" onClick={() => setUplaodNewImage(true)}>
                          <BoldCloseIcon />
                        </DeleteFile>
                      </ActionIconsWrapper>
                    </FileInputWrapper>
                  </UploadFileInput>
                ) : (
                  <>
                    {imageFile === null ? (
                      <UploadFileInput isImage isdropActive={isdropActive}>
                        <CenterDiv>
                          <UploadIcon />
                          {isdropActive ? (
                            <StyledPTag>{tCommon("drop_it_here")}</StyledPTag>
                          ) : (
                            <StyledPTag>
                              <span>{tCommon("select")}</span> {tCommon("cover_image")}
                            </StyledPTag>
                          )}
                        </CenterDiv>
                        <FileUploader
                          name="image"
                          types={imagesTypes}
                          maxSize={2}
                          onDraggingStateChange={(e) => setIsdropActive(e)}
                          handleChange={(e) => setImageFile(e)}
                          onSizeError={() => setUploadImageError(tCommon("maximum_allowed"))}
                          onTypeError={() => setUploadImageError(tCommon("wrong_type"))}
                        />
                      </UploadFileInput>
                    ) : (
                      <UploadFileInput isImage>
                        <FileInputWrapper>
                          <FileNameWrapper>
                            <SubjectImage src={URL.createObjectURL(imageFile)} alt="subject_image" />
                            <StyledPTag noMargin>
                              {imageFile.name} ({convertSize(imageFile.size)})
                            </StyledPTag>
                          </FileNameWrapper>
                          <DeleteFile type="button" onClick={() => setImageFile(null)}>
                            <BoldCloseIcon />
                          </DeleteFile>
                        </FileInputWrapper>
                      </UploadFileInput>
                    )}
                  </>
                )}
                {uploadImageError && imageFile === null && <ErrorType center>{uploadImageError}</ErrorType>}
              </div>
            </DashboardContainer>
            <PopupSectionTitle>{tCommon("subject_theme_color")}</PopupSectionTitle>
            <PickColorWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("subject_color")}
                {...register("color", {
                  required: {
                    value: true,
                    message: tCommon("required_message"),
                  },
                  pattern: {
                    value: /^#([0-9A-F]{3}){1,2}$/i,
                    message: tCommon("color_validation_message"),
                  },
                })}
                value={watch("color")}
                onChange={handleInputChange}
                error={Boolean(errors.color)}
                helperText={errors.color && errors?.color.message}
                InputLabelProps={{
                  shrink: watch("color") && true,
                }}
                InputProps={{
                  endAdornment: (
                    <DisplayColor pickedColor={color} onClick={() => setOpenColorPalltie(!openColorPalltie)} />
                  ),
                }}
                ref={colorPickerRef}
              />
              <ColorPickerContainer className="custom-chrome-picker" openColorPalltie={openColorPalltie}>
                <ChromePicker color={color} onChange={handleChange} />
              </ColorPickerContainer>
            </PickColorWrapper>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("light_dark_mode")}</InputLabel>
              <Controller
                name="is_light_theme"
                control={control}
                defaultValue={"true"}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("light_mode")}</MenuItem>
                    <MenuItem value="false">{tCommon("dark_mode")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <PopupSectionTitle>{tCommon("price")}</PopupSectionTitle>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("total_price")}
                value={subjectStatistics ? subjectStatistics?.price : ""}
                disabled
              />
              <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("currency")}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={subjectStatistics ? subjectStatistics?.currency : ""}
                  render={({ field }) => (
                    <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                      {currenciesList?.map((cur: string, index: number) => (
                        <MenuItem key={index} value={cur} onClick={() => setPlacement([])}>
                          {cur.toLocaleUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("final_price")}
                disabled={Boolean(subjectStatistics?.price === 0 || isRegularInstructor)}
                {...register("final_price", {
                  min: {
                    value: 0,
                    message: tCommon("discount_less_validation"),
                  },
                  max: {
                    value: subjectStatistics?.price,
                    message: tCommon("finalprice_validation"),
                  },
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                error={Boolean(errors.final_price)}
                helperText={errors.final_price && errors.final_price?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("discount")}
                disabled
                value={`${calculateDiscount(subjectStatistics?.price, watch("final_price"))}%`}
              />
            </InputsWrapper>
            <PopupSectionTitle>{tCommon("study_stage_college")}</PopupSectionTitle>
            <CategoriesWrappers>
              {placement?.map((placement, index) => (
                <DisplayBreadcrumb key={index}>
                  <BreadcrumbText isDisabled={isRegularInstructor}>{placement.hierarcyText}</BreadcrumbText>
                  <DeleteCategoryIcon isDisabled={isRegularInstructor} onClick={() => removeCat(placement.id)}>
                    <CloseIcon width="12" height="12" />
                  </DeleteCategoryIcon>
                </DisplayBreadcrumb>
              ))}
              <BreadcrumbPortalButton
                placement={placement}
                buttonRef={buttonRef}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                categoriesValidation={categoriesValidation}
              >
                {categoriesValidation && <CategoryError>{categoriesValidation}</CategoryError>}
                <BreadcrumbsMenuPortal
                  buttonRef={buttonRef}
                  isDropdownOpen={isDropdownOpen}
                  setIsDropdownOpen={setIsDropdownOpen}
                  setPlacement={setPlacement}
                  placement={placement}
                  currentCurrency={watch("currency")}
                />
              </BreadcrumbPortalButton>
            </CategoriesWrappers>
            <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
            <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="is_active"
                control={control}
                defaultValue={subjectStatistics ? subjectStatistics?.is_active.toString() : ""}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("active")}</MenuItem>
                    <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSubjectPopup;
