import { ErrorType, SpinnerContainer } from "@Components/PopupsLayout/PopupsLayout.styled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useQuery } from "react-query";
import { _GetCourseSections } from "@Services/Courses";
import { TeachersDatasContext } from "context/Teachers.context";
import FileIcon from "images/icons/FileIcon";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import {
  UploadFileInput,
  DashboardContainer,
  DeleteFile,
  CenterDiv,
  ErrorMessage,
  ErrorTitle,
  FileInputWrapper,
  FileNameWrapper,
  StyledPTag,
  VideoPlaceHolder,
  VideoPosition,
  DownloadFileWrapper,
} from "@Components/Popups/AddLecturePopup/AddLecturePopup.styled";
import { _DeleteUploadFile, _GetUploadUrl, _PutPdfFile } from "@Services/Common";
import { FileUploader } from "react-drag-drop-files";
import { _GetFileDownloadLink, _GetLecture, _UpdateLecture } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadPdf from "images/icons/UploadPdf";
import { FILE, VIDEO, docFileTypes } from "constants/Types";
import Vimeo from "@u-wave/react-vimeo";
import { useForm, Controller } from "react-hook-form";
import PopupsLayout from "@Components/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Lecture } from "types/Lecture";
import theme from "@Style/Theme";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import DownloadIcon from "images/icons/DownloadIcon";
import { downloadFile } from "utils/Strings";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}
const EditLecturePopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = userInfo();
  const [submitValidation, setSubmitValidation] = useState("");
  const [disablebutton, setDisablebutton] = useState(true);
  const { courseId, lectureId, lectureType, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);
  const { data: lectureData, isLoading: isLectureDataLoad } = useQuery(
    ["getLectureData", { lectureId, isOpen }],
    () => _GetLecture(lectureId, lectureType),
    {
      enabled: Boolean(lectureId !== 0 && lectureType),
      cacheTime: 0,
    }
  );
  const { data: getSections } = useQuery(["getsections", { lectureId, isOpen }], () => _GetCourseSections(courseId), {
    enabled: Boolean(isOpen && lectureId !== 0 && lectureType),
    cacheTime: 0,
  });
  const [lectureTypes, setLectureTypes] = useState("");
  // FILE STATES
  const [docFile, setDocFile] = useState(null);
  const [docFileName, setDocFileName] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileExe, setFileExe] = useState("");
  const [s3FileName, setS3FileName] = useState("");
  const [s3FileUrl, setS3FileUrl] = useState("");
  const [s3FileNameToDelete, setS3FileNameToDelete] = useState("");
  const [uploadFileError, setUploadFileError] = useState("");
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const { data: getFileDownloadableUrl } = useQuery(
    ["downloadableUrl", { lectureId, isOpen }],
    () => _GetFileDownloadLink(lectureData?.s3_file_name),
    {
      enabled: Boolean(isOpen && lectureData && lectureId !== 0 && lectureType === FILE),
      cacheTime: 0,
    }
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Lecture>();

  // FILE UPLOAD HANDLER
  const fileUploadhandler = async (eventObject) => {
    setDocFile(eventObject);
    setDisablebutton(false);
  };

  useEffect(() => {
    if (docFile) {
      const lastDotIndex = docFile?.name.lastIndexOf(".");
      setDocFileName(docFile?.name.substring(0, lastDotIndex));
      if (docFileName) {
        setFileName(docFile?.name.substring(0, lastDotIndex));
        setFileExe(docFile?.name.substring(lastDotIndex + 1));
      }
    }
  }, [docFile, docFileName]);

  useEffect(() => {
    if (fileName && fileExe) {
      _GetUploadUrl({ folder_path: process.env.REACT_APP_BUCKET_NAME, key: fileName, content_type: fileExe }).then(
        (res) =>
          _PutPdfFile(res.signed_url, docFile)
            .then((res) => {
              setS3FileName(res?.config.data.name);
              setS3FileUrl(res?.config.url);
              setS3FileNameToDelete(res?.config.url.split("/")[4].split("?")[0].split("%20").join(" "));
            })
            .catch((err) => setUploadFileError(err.message))
      );
    }
  }, [fileName, fileExe]);

  const deleteFile = () => {
    _DeleteUploadFile({
      data: {
        folder_path: process.env.REACT_APP_BUCKET_NAME,
        key: s3FileNameToDelete ? s3FileNameToDelete : lectureData?.s3_file_name?.split("%20").join(" "),
      },
    });
    setDocFile(null);
    setDocFileName([]);
    setFileName("");
    setFileExe("");
    setUploadFileError("");
  };

  useEffect(() => {
    if (lectureData) {
      reset({
        course_id: courseId,
        video_id: lectureData?.vimeo_video_id ? lectureData?.vimeo_video_id : "",
        vimeo_account: lectureData?.vimeo_account,
        section_id: lectureData?.section_id,
        display_name: {
          en: lectureData?.display_name?.en ? lectureData?.display_name?.en : "",
          tr: lectureData?.display_name?.tr ? lectureData?.display_name?.tr : "",
          ar: lectureData?.display_name?.ar ? lectureData?.display_name?.ar : "",
        },
      });
      setFileName(lectureData?.s3_file_name);
      setLectureTypes(isOpen ? lectureType : "");
    }
    setDocFile(null);
    setDocFileName([]);
    setSubmitValidation("");
    setUploadFileError("");
    setDisablebutton(true);
  }, [lectureData, isOpen]);

  const submitForm = (data: Lecture) => {
    const { vimeo_account, video_id, ...remainingData } = data;
    const { display_name, course_id, section_id } = data;
    setIsLoading(true);
    _UpdateLecture(
      lectureId,
      lectureType === FILE
        ? isRegularInstructor
          ? { display_name, course_id, section_id }
          : {
              ...remainingData,
              ...(s3FileName && { file_name: s3FileName }),
              ...(s3FileUrl && { file_url: s3FileUrl }),
              is_file_changed: Boolean(docFile !== null),
            }
        : isRegularInstructor
        ? { display_name }
        : {
            ...data,
            is_video_changed: Boolean(
              watch("video_id") !== undefined && lectureData?.vimeo_video_id !== watch("video_id")
            ),
          },
      teacherId,
      lectureType
    )
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_lecture")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton) || uploadFileError !== ""}
          closePopup={closePopup}
          showDeleteButton={lectureTypes ? true : false}
          deleteTitle={tCommon("delete_lecture")}
          deleteButtonStatus={isRegularInstructor}
          noteMessage={tCommon("Lecture_note_message")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {isLectureDataLoad ? (
              <SpinnerContainer
                style={{
                  color: theme.colors.seagule,
                }}
              >
                <CircularProgress color="inherit" size={50} />
              </SpinnerContainer>
            ) : (
              <Fragment>
                {lectureType ? (
                  <Fragment>
                    <InputsWrapper>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                        <Select
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          defaultValue={"true"}
                          MenuProps={MenuProps}
                          disabled
                        >
                          <MenuItem value="true">
                            {lectureTypes === VIDEO ? tCommon("video") : tCommon("file")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {lectureTypes === VIDEO && (
                        <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                          <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                          <Controller
                            name="vimeo_account"
                            control={control}
                            defaultValue={lectureData ? lectureData?.vimeo_account : ""}
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="demo-simple-select"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={MenuProps}
                                {...register("vimeo_account")}
                              >
                                <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                                <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      )}
                    </InputsWrapper>
                    {lectureTypes === FILE && (
                      <Fragment>
                        <DashboardContainer>
                          <div className="container">
                            {!fileName ? (
                              <Fragment>
                                {docFile === null ? (
                                  <UploadFileInput>
                                    <CenterDiv>
                                      <StyledPTag>{tCommon("drop_here")}</StyledPTag>
                                      <UploadPdf />
                                    </CenterDiv>
                                    <FileUploader handleChange={fileUploadhandler} name="file" types={docFileTypes} />
                                  </UploadFileInput>
                                ) : (
                                  <UploadFileInput>
                                    <FileInputWrapper>
                                      <FileNameWrapper>
                                        <FileIcon />
                                        <StyledPTag noMargin>{docFile?.name}</StyledPTag>
                                      </FileNameWrapper>
                                      <DeleteFile
                                        type="button"
                                        onClick={() => {
                                          setDocFile(null);
                                          deleteFile();
                                        }}
                                      >
                                        <BoldCloseIcon />
                                      </DeleteFile>
                                    </FileInputWrapper>
                                  </UploadFileInput>
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                <UploadFileInput>
                                  <FileInputWrapper>
                                    <FileNameWrapper>
                                      <FileIcon />
                                      {!docFile ? (
                                        <StyledPTag noMargin>{lectureData?.file_name}</StyledPTag>
                                      ) : (
                                        <StyledPTag noMargin>{docFile?.name}</StyledPTag>
                                      )}
                                    </FileNameWrapper>
                                    {!docFile && (
                                      <DownloadFileWrapper
                                        onClick={() =>
                                          downloadFile(getFileDownloadableUrl?.signed_url, lectureData?.file_name)
                                        }
                                      >
                                        <DownloadIcon />
                                      </DownloadFileWrapper>
                                    )}
                                    <DeleteFile
                                      type="button"
                                      disabled={isRegularInstructor}
                                      onClick={() => {
                                        setDocFile(null);
                                        deleteFile();
                                      }}
                                    >
                                      <BoldCloseIcon />
                                    </DeleteFile>
                                  </FileInputWrapper>
                                </UploadFileInput>
                                {uploadFileError && <ErrorType center>{uploadFileError}</ErrorType>}
                              </Fragment>
                            )}
                          </div>
                        </DashboardContainer>
                        <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                          <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                          <Controller
                            name="section_id"
                            control={control}
                            defaultValue={lectureData ? lectureData?.section_id : ""}
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="demo-simple-select"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={MenuProps}
                              >
                                {getSections?.map((sec: any, index: number) => (
                                  <MenuItem key={index} value={sec?.id}>
                                    {sec?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Fragment>
                    )}
                    {lectureTypes === VIDEO && (
                      <Fragment>
                        {lectureData?.vimeo_video_id ? (
                          <VideoPosition>
                            <Vimeo
                              className="video-player-style"
                              video={lectureData?.vimeo_video_id}
                              controls
                              width={495}
                              paused={!isOpen ? true : false}
                            />
                          </VideoPosition>
                        ) : (
                          <VideoPlaceHolder>
                            <ErrorTitle>{tCommon("sorry")}</ErrorTitle>
                            <ErrorMessage>{tCommon("vimeo_not_found")}</ErrorMessage>
                          </VideoPlaceHolder>
                        )}
                        <Input
                          id="filled-basic"
                          disabled={isRegularInstructor}
                          label={tCommon("vimeo_video_id")}
                          variant="filled"
                          {...register("video_id", {
                            valueAsNumber: true,
                          })}
                        />
                        <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                          <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                          <Controller
                            name="section_id"
                            control={control}
                            defaultValue={lectureData ? lectureData?.section_id : ""}
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="demo-simple-select"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={MenuProps}
                              >
                                {getSections?.map((sec: any, index: number) => (
                                  <MenuItem key={index} value={sec?.id}>
                                    {sec?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Fragment>
                    )}
                    <MultibleLanguagesInputs
                      islecture
                      data={lectureData}
                      isOpen={isOpen}
                      setValue={setValue}
                      errors={errors}
                      register={register}
                      setDisablebutton={setDisablebutton}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <ErrorType>{tCommon("lecture_not_found", { count: lectureId })}</ErrorType>
                    <ErrorType>{tCommon("contact_success_team")}</ErrorType>
                  </Fragment>
                )}
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditLecturePopup;
